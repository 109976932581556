import React from "react";
class Header extends React.Component {
    render() {
      return (
        <div className="headerContainer">
            <h1 className="NameTitle">Charles Lemaire</h1>
        </div>
      );
    }
}

export default Header;